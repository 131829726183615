<template>
  <div>
    <card>
      <template v-slot:card-title>
        <span class="card-title mb-16">Mot de passe</span>
      </template>
      <template v-slot:card-content>
        <edit-password-form />
      </template>
    </card>
  </div>
</template>
<script>
import Card from "@/components/base/card";
import EditPasswordForm from "./edit-password-form";

export default {
  name: "password-change",
  components: {
    EditPasswordForm,
    Card
  }
};
</script>
