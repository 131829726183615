<template>
  <v-form
    v-model="isValid"
    @keyup.native.enter="change_password"
  >
    <TextInput id="old_password" :rules="passwordRules" label="Ancien mot de passe" type="password" v-model="old_password" />
    <TextInput id="new_password" :rules="passwordRules" label="Nouveau mot de passe" type="password" v-model="new_password" />
    <TextInput id="new_password" :rules="passwordRules" label="Confirmer le mot de passe" type="password" v-model="c_newpassword" />
    <v-card-actions>
      <v-spacer />
      <Button :disabled="!isValid" :loading="loading" action_name="Enregistrer" style="width: 100%" @clicked="change_password"  />
      <v-spacer />
    </v-card-actions>
    <v-snackbar v-model="snackbar" :timeout="timeout">
      {{ text }}
      <v-btn color="blue" text @click="snackbar = false">
        Close
      </v-btn>
    </v-snackbar>
  </v-form>
</template>
<script>
import { TextInput, Button } from "@/components/base";
import { changePassword } from "@/services/users.api";

export default {
  components: { TextInput, Button },
  methods: {
    change_password() {
      this.loading = true;
      changePassword(
        this.old_password,
        this.new_password,
        this.c_newpassword
      ).then(
        () => {
          this.text = "Mot de passe changé avec succès";
          this.snackbar = true;
          this.$store.dispatch('logout');
        },
        error => {
          this.text = error.response.data;
          this.snackbar = true;
          this.loading = false;
        }
      );
    }
  },
  data() {
    return {
      text: "",
      snackbar: false,
      timeout: 2000,
      loading: false,
      isValid: true,
      old_password: "",
      new_password: "",
      c_newpassword: "",
      passwordRules: [
        v => !!v || "Password is required",
        v =>
          (v && v.length >= 6) || "Password must be greater than 6 characters"
      ]
    };
  }
};
</script>
